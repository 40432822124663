<template>
  <div class="chrome">
    <b-container>
      <b-row align-v="center" id="box" class="text-center">
        <b-col></b-col>
        <b-col cols="9">
            <img src="https://www.google.com/chrome/static/images/chrome-logo.svg">
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-row align-v="center" id="msg" class="text-center">
        <b-col></b-col>
        <b-col cols="9">
            <h1>Esta aplicação necessita do uso do Google Chrome</h1>
            Clique no link abaixo para iniciar a instalação.
        </b-col>
        <b-col></b-col>
      </b-row>
      <b-row align-v="center" id="btn" class="text-center">
        <b-col></b-col>
        <b-col cols="4">
            <b-button block variant="primary" @click="chrome">Visitar a página do Chrome</b-button>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: "Chrome",
  components: {
  },
  async created() {
  },
  data() {
    return {
    };
  },
  methods: {
    chrome() {
      window.location = 'https://www.google.com/chrome/';
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
img {
  width: 70px;
}
#box {
  margin-top: 250px;
  width: 95%;
}
#msg {
  margin-top: 25px;
  width: 95%;
}
#btn {
  margin-top: 25px;
  width: 95%;
}
</style>

<style>
.videobg-content {
  background-color: #f9fafe;
  background-image: url('https://www.google.com/chrome/static/images/hero-back-large-desktop.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1740px 760px;
}
.video-wrapper {
    display: none !important;
}
</style>